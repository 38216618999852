import { Link } from 'gatsby';
import * as React from 'react';
import { Container, Menu } from 'semantic-ui-react';
import siteConfig from '../../site-config';

export default ({ links }) => (
  <Container>
    <Menu size="massive" className="handle" text borderless style={{ backgroundColor: "white" }}>
      <Container text>
        <Menu.Item header>
          <Link to={`/`} style={{ color: "black" }}>{siteConfig.title}</Link>
        </Menu.Item>
        <Menu.Menu position="right">
          {links.map(({ text, path }, idx) => (
            <Menu.Item key={idx}>
              <Link style={{ color: "black" }} to={path}>
                {text}
              </Link>
            </Menu.Item>
          ))}
        </Menu.Menu>
      </Container>
    </Menu>
  </Container>
);
