import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Container } from 'semantic-ui-react';
import SiteFooter from './SiteFooter';
import SiteHeader from './SiteHeader';

const navLinks = [
    {
        text: 'Blog',
        path: '/blog',
    },
    {
        text: 'About',
        path: '/about',
    },
];

const Theme = ({ children }) => {
    const pageQuery = graphql`
        query {
            site {
                siteMetadata {
                    title
                    description
                }
            }
        }
    `;
    return (
        <Container
            text
            style={{
                display: 'flex',
                minHeight: '100vh',
                flexDirection: 'column',
            }}
        >
            <StaticQuery
                query={pageQuery}
                render={data => {
                    const { siteMetadata } = data.site;
                    const siteTitle = siteMetadata.title;
                    const siteDescription = siteMetadata.description;
                    return (
                        <Helmet
                            htmlAttributes={{ lang: 'en' }}
                            meta={[
                                {
                                    name: 'description',
                                    content: siteDescription,
                                },
                            ]}
                            title={siteTitle}
                        />
                    );
                }}
            />
            <Container>
                <SiteHeader links={navLinks} />
            </Container>
            <Container style={{ paddingTop: '3em', flexGrow: '1' }}>
                {children}
            </Container>
            <Container as="footer">
                <SiteFooter />
            </Container>
        </Container>
    );
};

const mapStateToProps = ({ themeType }) => {
    return { themeType };
};

const mapDispatchToProps = dispatch => {
    return { themeSwitch: () => dispatch({ type: `CHANGE_THEME` }) };
};

const ConnectedTheme = connect(mapStateToProps, mapDispatchToProps)(Theme);

export default function Layout({ children }) {
    return <ConnectedTheme>{children}</ConnectedTheme>;
}
