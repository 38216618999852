import { Link } from 'gatsby';
import * as React from 'react';
import { Container } from 'semantic-ui-react';

// export default () => (
//   <footer>
//     <Container>
//       {/* <div dangerouslySetInnerHTML={{ __html: siteConfig.footerText }} /> */}

//       {/* <Typography variant="body2" color="textSecondary" align="center">
// {'Copyright © '}
// <Link color="inherit" href="https://material-ui.com/">
//   Your Website
// </Link>{' '}
// {new Date().getFullYear()}
//       {'.'}
//     </Typography> */}


//     </Container>
//   </footer>
// );
export default () => (
  <Container text textAlign="center" style={{ paddingBottom: "1em" }}>
    <Link to="/" >amilstead.com</Link>{` © ${new Date().getFullYear()}`}
  </Container>
)
