module.exports = {
    //
    // Change information to your own:
    //
    // Site Info:
    title: 'amilstead.com',
    description: 'amilstead.com',
    siteUrl: 'https://amilstead.com/',

    // Blog Author Info:
    author: 'Alex Milstead',
    authorBio: '',
    social: {
        github: 'amilstead',
    },

    // Services:
    googleAnalyticsTrackingId: `ADD YOUR TRACKING ID HERE`,
};
